<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading" :can-cancel="false" />
        <div v-if="isLive" class="alert alert-danger" style="text-align: center; font-weight: bold;" role="alert">
            You are Managing the Live Server Data
        </div>
         <CRow>
            <CCol sm="6">
                <CRow>
                    <CCard>
                        <CCardHeader>
                            <CIcon name="cil-terrain"/><strong> Zone {{ zone.displayName }} </strong>
                            <div class="card-header-actions">
                                <span style="padding: 0px 20px;"><CBadge :color="$config.flagClr(zone.flags)">{{ $config.flagTxt(zone.flags) }}</CBadge></span>
                                <CBadge :color="$config.statusClr(this.instances.length > 0)">{{ $config.statusTxt(this.instances.length > 0) }}</CBadge>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <dl class="row">
                                <dt class="col-sm-3">ID</dt>
                                <dd class="col-sm-9">{{ zone.zoneId }}</dd>

                                <dt class="col-sm-3">Name</dt>
                                <dd class="col-sm-9">{{ zone.displayName }}</dd>

                                <dt class="col-sm-3">Scene</dt>
                                <dd class="col-sm-9">{{ zone.sceneName }}</dd>

                                <dt class="col-sm-3">Population</dt>
                                <dd class="col-sm-9">{{ zone.defaultPopulationThreshold }}</dd>

                                <dt class="col-sm-3">Network</dt>
                                <dd class="col-sm-9">{{ zone.address }}</dd>

                                <dt class="col-sm-3">Deployed</dt>
                                <dd class="col-sm-9">{{ isDeployed }}</dd>
                            </dl>
                        </CCardBody>
                    </CCard>
                </CRow>
                <CRow>
                    <CCol>
                        <CCard>
                            <CCardHeader>
                                <slot name="header">
                                    <CIcon name="cil-fire"/><strong>  Instances </strong>
                                    <div class="card-header-actions">
                                        <small class="text-muted">Total: {{ instances.length }}</small>
                                    </div>
                                </slot>
                            </CCardHeader>
                            <CCardBody>
                                <CDataTable
                                        :items="instances"
                                        :fields="instanceFields"
                                        :hover="hover"
                                        :loading="isLoading"
                                        :items-per-page="10"
                                        pagination >
                                    <template #threshold="{ item }">
                                        <td class="py-2">
                                            <CInput v-model="item.populationThreshold" />
                                        </td>
                                    </template>
                                    <template #kill="{item}">
                                        <td class="py-2">
                                            <CButton    color="danger"
                                                        square
                                                        size="sm"
                                                        @click="openModal(item)">
                                                Stop
                                            </CButton>
                                        </td>
                                    </template>
                                    <template #update="{item}">
                                        <td class="py-2">
                                            <CButton
                                                color="primary"
                                                variant="outline"
                                                square
                                                size="sm"
                                                @click="updateThreshold(item)" >
                                                Update
                                            </CButton>
                                        </td>
                                    </template>
                                </CDataTable>
                            </CCardBody>
                            <CCardFooter>
                                <div  v-if="isDeployed">
                                    <CButton    color="success"
                                                variant="outline"
                                                square
                                                size="sm"
                                                @click="startZone(zone.sceneName)">
                                        Start New Instance
                                    </CButton>
                                </div>
                                <div v-else>
                                    N/A
                                </div>
                            </CCardFooter>
                        </CCard>
                    </CCol>
                </CRow>
            </CCol>
            <CCol sm="6">
                <CRow>
                    <CCol>
                        <CCard>
                            <CCardHeader>
                                <CIcon name="cil-globe-alt"/><strong> World {{ world.world_name }} </strong>
                                <div class="card-header-actions">
                                    <CBadge :color="$config.flagClr(world.flags)">{{ $config.flagTxt(world.flags) }}</CBadge>
                                </div>
                            </CCardHeader>
                            <CCardBody>
                                <dl class="row">
                                    <dt class="col-sm-3">ID</dt>
                                    <dd class="col-sm-9">{{ world.world_id }}</dd>

                                    <dt class="col-sm-3">Name</dt>
                                    <dd class="col-sm-9">{{ world.world_name }}</dd>

                                    <dt class="col-sm-3">Network</dt>
                                    <dd class="col-sm-9">{{ world.ip }}:{{ world.port }}</dd>

                                    <dt class="col-sm-3">Key</dt>
                                    <dd class="col-sm-9">{{ world.world_key }}</dd>
                                </dl>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <CCard>
                            <CCardHeader>
                                <CIcon name="cil-settings"/><strong>  Update Zone</strong>
                            </CCardHeader>
                            <CCardBody>
                                <CRow form class="form-group" key="flagform">
                                    <CCol sm="3">
                                        Access
                                    </CCol>
                                    <CCol sm="9">
                                        <div class="custom-control custom-checkbox" v-for="flag in flags" :key="flag.id">
                                            <input class="custom-control-input" type="checkbox" :id="flag.id" :value="flag.id" v-model="selectedFlags">
                                            <label class="custom-control-label" :for="flag.id">{{ flag.flag }}</label>
                                        </div>
                                    </CCol>
                                </CRow>
                                <CRow form class="form-group" key="keepAliveform">
                                    <CCol sm="3">
                                        Keep Alive
                                    </CCol>
                                    <CCol sm="9">
                                        <div class="custom-control custom-checkbox" >
                                            <input class="custom-control-input" type="checkbox" id="keepAliveCheck" v-model="zone.keepAlive">
                                            <label class="custom-control-label" for="keepAliveCheck"></label>
                                        </div>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                            <CCardFooter>
                                <CButton color="primary" class="px-4" @click="updateZone" :disabled="isUpdating">
                                    <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                                    {{ btnTxt }}
                                </CButton>
                            </CCardFooter>
                        </CCard>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
        <CModal title="Stop Instance" color="danger"  :show.sync="killModal" @update:show="closeModal">
            Are you sure you wish to stop Instance {{ instanceToKill.Instance }}?
        </CModal>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: "Zone",
        components: {
            Loading
        },
        props: [ "id" ],
        data(){
            return {
                zone: { },
                instances: [],
                newInstanceId: 0,
                world: {},
                flags: [],
                selectedFlags: [],
                instanceToKill: {},
                isDeployed: false,
                hover: true,
                isUpdating : false,
                isLoading : false,
                zoneLoading : false,
                activeLoading: false,
                killModal: false,
                btnTxt: "Update",
                instanceFields: [
                    { key: "Pid", label: "PID" },
                    { key: 'Instance', label: 'Instance' },
                    { key: 'playerCount', label: 'Players' },
                    { key: 'threshold', label: 'Threshold' },
                    { key: 'update', label: '' },
                    { key: 'kill', label: '' },
                ],
            }
        },
        watch: {
            isUpdating: function(){
                this.btnTxt = (this.isUpdating ? 'Please Wait...' : 'Update');
            }
        },
        computed: {
            isLive: function () {
                return (localStorage.env === "live");
            }
        },
        methods: {
            getZone() {
                this.zoneLoading = true;
                this.isLoading = true;

                this.$http.get(this.$config.gameapi + '/Zone/' + localStorage.env + '/' + this.id, { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.zone = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.getWorld(this.zone.worldId);
                });
            },
            getWorld(worldId) {
                this.$http.get(this.$config.gameapi + '/World/' + localStorage.env + '/' + worldId, { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.world = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.getFlags();
                    this.getActive();
                });
            },
            getActive() {
                this.activeLoading = true;
                this.isLoading = true;

                this.$http.get(this.$config.zc[localStorage.env] + '/' + localStorage.env + '/procs', { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    
                    this.isDeployed = jsonData.IsDeployed;
                    let ix = [];

                    for (let i = 0; i < jsonData.RunningZones.length; i++) {
                        if (jsonData.RunningZones[i].Zone == this.zone.sceneName) {

                            if (this.newInstanceId <= jsonData.RunningZones[i].Instance) {
                                this.newInstanceId = jsonData.RunningZones[i].Instance + 1;
                            }

                            ix.push(jsonData.RunningZones[i]);
                        }
                    }

                    this.getPops(ix);

                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                });
            },
            getPops(ix) {
                this.$http.get(this.$config.gameapi + '/Zone/' + localStorage.env + '/active/' + this.id, { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    
                    this.instances = [];
                    for (let i = 0; i < jsonData.length; i++) {
                        let it = ix.filter(x => x.Instance == jsonData[i].instanceId)[0];
                        
                        if (it) {
                            it.id = jsonData[i].id;
                            it.playerCount = jsonData[i].playerCount;
                            it.populationThreshold = jsonData[i].populationThreshold;

                            this.instances.push(it);
                        }
                    }
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.activeLoading = false;
                    if (!this.zoneLoading) {
                        this.isLoading = false;
                    }
                });
            },
            getFlags() {
                this.activeLoading = true;
                this.isLoading = true;
                this.flags = [];

                this.$http.get(this.$config.gmapi + '/GameFlag/set?incSub=true', { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.flags = jsonData;
                    
                    for (let i = 0; i < this.flags.length; i++) {
                        if ((this.zone.flags & this.flags[i].id) == this.flags[i].id)
                            this.selectedFlags.push(this.flags[i].id);
                    }

                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.zoneLoading = false;
                    if (!this.activeLoading) {
                        this.isLoading = false;
                    }
                });
            },
            startZone(scene) {
                this.isLoading = true;
                this.$http.post(this.$config.zc[localStorage.env] + '/' + localStorage.env + '/zones/start/' + scene + '?instance=' + this.newInstanceId, null,{ headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    if (jsonData.status){
                        setTimeout(() => { this.getActive(); }, 5000);
                    } else {
                        this.isLoading = false;
                        this.$root.$emit('sendMsg', "API Error", "danger", "Unable to start Zone: " + jsonData.message );
                    }
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                });
            },
            stopZone(pid) {
                this.isLoading = true;
                this.$http.post(this.$config.zc[localStorage.env] + '/' + localStorage.env + '/zones/kill/' + pid, null,{ headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    if (jsonData.status){
                        setTimeout(() => { this.getActive(); }, 5000);
                    } else {
                        this.isLoading = false;
                        this.$root.$emit('sendMsg', "API Error", "danger", "Unable to kill Zone: " + jsonData.message );
                    }
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                });
            },
            openModal(ik) {
                this.instanceToKill = ik;
                this.killModal = true;
            },
            closeModal(status, evt) { 
                if (evt.target.innerText.trim() === "OK") {
                    this.stopZone(this.instanceToKill.Pid);
                } 
                this.instanceToKill = {};
                this.killModal = false;
            },
            updateThreshold(t) {
                this.isLoading = true;
                this.$http.put(this.$config.gameapi + '/Zone/' + localStorage.env + '/active', t, {headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.getActive();
                }).catch(function (error) {
                    this.$root.$emit('onErr', error);
                    this.isLoading = false;
                }); 
            },
            updateZone(e){
                e.preventDefault();
                this.isUpdating = true;
                const arrSum = this.selectedFlags.reduce((a, b) => a + b, 0);
                this.zone.flags = arrSum;
                this.$http.put(this.$config.gameapi + '/Zone/' + localStorage.env, this.zone, {headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.zone = jsonData;
                    this.isUpdating = false;
                }).catch(function (error) {
                    this.$root.$emit('onErr', error);
                    this.isUpdating = false;
                });
            }
        },
        created() {
            this.getZone();
        }
    }
</script>

<style scoped>

</style>
